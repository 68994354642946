import Navigation from './Navigation'
import Footer from './Footer'

const ProtestTag = () => {
  return(
    <div className='container'>
      <Navigation />
      <main className='cover-container px-3 textg-start p5'>
      <h1>
        Kein Werben fürs Sterben<br />
        <small>Protest gegen den »Tag der Bundeswehr«</small>
      </h1>
      <p>
        Hinter Kasernenmauern und -zäuneder Streitkräfte schauen, die
        Armee mit ihrer Technik und ihrem Gerät hautnah erleben und die
        gesamte Vielfalt der Bundeswehr kennenlernen - live, bunt und zum Anfassen!« –
        diesen ›Tag der Bundeswehr‹ hat sich 2015 die damalige Verteidigungsministerin Ursula
        von der Leyen ausgedacht.
      </p>

      <h2>Kein Werben fürs Sterben</h2>
      <p>
        Wir lehnen diese Werbe- und Rekrutierungsveranstaltung für die Bundeswehr ab.
        Mit diesem Fest sollen Hemmschwellen gegenüber dem Dienst an der Waffe
        abgebaut und das Militärische zu einem Teil unseres Alltags gemacht werden. Die
        Bundeswehr ist kein »normaler Arbeitgeber«.
      </p>
      <p>
        Jährlich wird ein dreistelliger Millionenbetrag ausgegeben,
        um die Akzeptanz der Auslandseinsätze
        in der Bevölkerung zu steigern und Nachwuchs zu gewinnen.
        Nicht erst seit dem russischen Angriff auf die Ukraine hat die Bundeswehr
        ein massives Rekrutierungsproblem.
        Dass Soldat*innen andere Menschen töten und selbst immer wieder traumatisiert,
        verwundet oder getötet werden, wird nicht thematisiert.
        Auch nicht, was die vergangenen Auslandseinsätze in den Einsätzen
        angerichtet und hinterlassen haben.
      </p>

      <h2>Aufrüstung bringt keinen Frieden</h2>

      <p>
        Dem Tag der Bundeswehr kommt im Jahr 2023 eine besondere Bedeutung zu. Die
        Bundesregierung hat mit ihrer Zeitenwende und erweitertem NATO-Engagement mit
        einer bisher kaum vorstellbaren Geschwindigkeit die Haltung zu Aufrüstung,
        zu Waffenlieferungen, zur Entspannungspolitik der vergangenen Jahrzehnte und zur Rolle der
        Bundeswehr weitere Tabus gebrochen und Forderungen durchgesetzt, die bisher
        gesellschaftlich nicht durchsetzbar waren.
      </p>
      <p>
        Die Bundesregierung betreibt ein gigantisches Aufrüstungsprogramm, von dem vor
        allem die Rüstungskonzerne profitieren. Der DAX-Aufstieg von Rheinmetall, einem
        Konzern, der vor allem am Töten und Zerstören verdient, ist symbolträchtig, er
        zeigt, wie kaputt und menschenverachtend der Kapitalismus ist. Die 100 Milliarden Euro
        Sondervermögen und das Ziel, den Bundeswehretat auf über 2 Prozent des Bruttoin-
        landsproduktes festzulegen, zeigen zudem, dass fehlendes Geld für die Bewältigung der
        Krisen unserer Zeit nie das Problem war.
      </p>

      <h2>Aktiv werden statt Abwarten!</h2>
      <div className='row'>
        <div className='col-md-6 offset-md-3'>
        <div className='card mb-5 mt-3'>
          <div className='card-body text-center'>
            <p className='lead'>
              PROTEST<br />
              FREITAG 16. JUNI<br />
              17:00 UHR<br />
              BUNDESWEHRSHOWROOM<br />
              Dorothea-Schlegel-Platz / SBhf Friedrichstraße
              <br />
              Mach mit!
            </p>
          </div>
          </div>
        </div>
      </div>
      <ul>
        <li>Keine Rekrutierung Minderjähriger!</li>
        <li>Kein Werben für die Bundeswehr an
        Hochschulen, Jobcentern oder Schulen!</li>
        <li>Abschaffung (nicht nur Aussetzung) der
        Wehrpflicht!</li>
        <li>100 Milliarden für eine klimagerechte
        Energiewende, Bildung, ein Gesund-
        heitssystem, das Gesundheit vor Profite
        stellt und Pfleger*innen gerecht bezahlt
        sowie für die öffentliche Daseinsvorsorge,
        anstatt für das Militär!</li>
      </ul>
      <p>
        Hier der Aufruf als <a href='/aufruf-protest-tag-der-bundeswehr.pdf'>PDF</a><br />
        Und ein <a href='/kein-werben-sharepic.jpg'>Sharepic</a>
      </p>

      <p><em>Aufrufer:innen</em></p>
      <ul className='small list-inline'>
        <li className='list-inline-item'>Attac Berlin</li>
        <li className='list-inline-item'>IDK e.V.</li>
        <li className='list-inline-item'>LINKE Berlin</li>
        <li className='list-inline-item'>Linksjugend ['solid] Berlin</li>
        <li className='list-inline-item'>LAG Betrieb und Gewerkschaft</li>
        <li className='list-inline-item'>LINKE Charlottenburg-Wilmersdorf</li>
        <li className='list-inline-item'>LINKE OV Kreuzberg </li>
        <li className='list-inline-item'>LINKE Neukölln</li>
        <li className='list-inline-item'>Basisinitiative „Auch wir sind DIE LINKE“ Treptow-Köpenick</li>
        <li className='list-inline-item'>Naturfreunde Berlin</li>
        <li className='list-inline-item'>VVN-BdA Berlin</li>
        <li className='list-inline-item'>SDS Berlin</li>
        <li className='list-inline-item'>Mahnwache gegen Atomwaffen</li>
        <li className='list-inline-item'>pax christi - Deutsche Sektion e.V.</li>
        <li className='list-inline-item'>AG Linksaktiv Treptow-Köpenick</li>
      </ul>
    </main>
    <Footer />
    </div>
  )
}

export default ProtestTag
