import React from 'react'
import Navigation from './Navigation'
import Footer from './Footer'
import SimpleSlider from './SimpleSlider'
import img1 from '../images/01-03-09-23.jpeg'
import img2 from '../images/02-03-09-23.jpeg'
import img3 from '../images/03-03-09-23.jpeg'
import img4 from '../images/04-03-09-23.jpeg'
import img5 from '../images/05-03-09-23.jpeg'
import img6 from '../images/06-03-09-23.jpeg'
import img7 from '../images/07-03-09-23.jpeg'
import img8 from '../images/08-03-09-23.jpeg'
import img9 from '../images/09-03-09-23.jpeg'

const AntikriegsTag2023 = () => {

  const images = [
    { name: img9, caption: 'Antikriesdemonstration 3.9.2023' },
    { name: img1, caption: 'Antikriesdemonstration 3.9.2023' },
    { name: img2, caption: 'Antikriesdemonstration 3.9.2023' },
    { name: img3, caption: 'Antikriesdemonstration 3.9.2023' },
    { name: img4, caption: 'Antikriesdemonstration 3.9.2023' },
    { name: img5, caption: 'Antikriesdemonstration 3.9.2023' },
    { name: img6, caption: 'Antikriesdemonstration 3.9.2023' },
    { name: img7, caption: 'Antikriesdemonstration 3.9.2023' },
    { name: img8, caption: 'Antikriesdemonstration 3.9.2023' }
  ]

  return(
    <div className='container'>
      <Navigation />
      <main className='cover-container px-3 textg-start p5'>
        <h1 className='mb-5'>
          <span style={{textDecoration: "underline", fontSize: "0.6em"}}>AUFRUF</span><br />
          Nein zu Krieg und Aufrüstung
        </h1>


        <img src='/antikriegstag-2023.jpg' alt='antikriegstag 2023' className='img img-fluid mb-5'/>



        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <div className='card mb-5 mt-3'>
              <div className='card-body'>
                <ul>
                  <li>
                    <a href='#aufruf'>Aufruf</a>
                  </li>
                  <li>
                    <a href='#presseschau'>Presseschau</a>
                  </li>
                  <li>
                    <a href='#pressemitteilung'>Auswertung, Pressemitteilung</a>
                  </li>
                  <li>
                    <a href='#eindruecke'>Bilder und Eindrücke</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h2 id='aufruf'>Aufruf</h2>

        <p>
          Seit mehr als eineinhalb Jahren tobt in der Ukraine ein brutaler Stellungskrieg.
          Jeden Tag sterben unzählige Soldaten in den Schützengräben, weiterhin werden
          jeden Tag Männer, Frauen und Kinder verwundet, getötet und vertrieben. Dieser
          Krieg hat Millionen ihre Heimat geraubt und zur Flucht gezwungen, er zerstört die
          Umwelt und das Klima.
          <br />
          Unsere Solidarität gilt den Menschen in der Ukraine, in Russland und weltweit,
          die unter dem Krieg und seinen Folgen leiden. Allen, die desertieren oder sich
          durch Kriegsdienstverweigerung und Flucht dem Krieg entziehen, allen die
          Widerstand leisten, gebührt unsere Unterstützung.
        </p>
          <ul>
            <li>
              Wir verurteilen den russischen Angriff auf die Ukraine und Russlands
              fortgesetzte Eskalation des Kriegs. Ebenso verurteilen wir die anhaltende
              Eskalation seitens der NATO-Staaten, die wie die EU einen Anteil an der
              Zuspitzung des Konfliktes um die Ukraine hat.
            </li>
            <li>
              Wir fordern von der Bundesregierung einen Stopp der Waffenlieferungen und
              demonstrieren für einen sofortigen Waffenstillstand.
            </li>
            <li>
              Wir fordern die Aufnahme und Bleiberecht für alle Geflüchteten sowie Asyl
              für alle Deserteur*innen.
            </li>
          </ul>
        <p>
          Auch Menschen in anderen Ländern leiden unter den Folgen von rücksichtsloser
          Geopolitik, von Gewalt und Krieg. Die Kriege im Nahen und Mittleren Osten,
          im Jemen, Sudan, Mali oder in den kurdischen Gebieten gehen weiter und werden
          von EU, NATO und Bundesregierung befeuert. So wenig es in den Kriegen in
          Afghanistan, Syrien und Irak um »westliche Werte, Demokratie und Freiheit«
          ging und geht, so wenig werden sie jetzt in der Ukraine und an den anderen
          Kriegsschauplätzen verteidigt.
        </p>
        <p>
          Sowohl Russland als auch dem Westen geht es darum, ihren weltpolitischen
          Einfluss zu erhalten und auszudehnen. Die NATO- und EU-Staaten, einschließlich
          der Bundesregierung, wollen ihre wirtschaftlichen und machtpolitischen Interessen
          durchsetzen. Sie planen bereits wie die hochverschuldete Ukraine zu einem
          zuverlässigen Partner für das westliche Kapital werden kann. Ausgerechnet die
          größte US-amerikanische Investmentfirma Blackrock soll den Wiederaufbau der
          Ukraine koordinieren.
        </p>

        <ul>
          <li>
            Wir stellen den Interessen von Banken und Konzernen die Interessen der
            Menschen entgegen.
          </li>
          <li>
            Wir unterstützen die Menschen, die sich gegen den neoliberalen Ausverkauf
            ihres Landes stellen.
          </li>
          <li>
            Wir stehen an der Seite derjenigen, die weltweit gegen die verschärfte
            Nahrungsmittelkrise und den dramatischen Anstieg der Preise kämpfen.
          </li>
        </ul>

        <p>
          Wir wollen raus aus dem Konkurrenzkampf von Nationen, Machtblöcken und
          Konzernen – hin zu einer Welt ohne Krieg, in der Menschen vor Profiten stehen
          und ein gutes Leben für alle möglich wird.
        </p>
        <p>
          Der Krieg in der Ukraine und um die Ukraine hat zu einer neuen Welle der
          Aufrüstung geführt und die Militarisierung beschleunigt. Die Bundesregierung will
          den Militärhaushalt um weitere sieben Milliarden wachsen lassen und damit das
          Zwei-Prozent-Ziel der NATO erreichen. Konzerne wie Rheinmetall und Krauss-Maffei
          Wegman fahren Rekordprofite ein. Der Druck, mehr junge Menschen für die Bundeswehr
          zu rekrutieren, nimmt zu.
        </p>

        <ul>
          <li>
            Wir lehnen die Aufrüstung und Militarisierung Deutschlands ab und fordern
            von der Bundesregierung, die vielen Milliarden Euro für Soziales,
            Gesundheit, Bildung und Klimaschutz auszugeben.
          </li>
          <li>
            Wir lehnen Werbung der Bundeswehr an Jobcentern, Schulen und Hochschulen sowie
            die Rekrutierung Minderjähriger ab.
          </li>
          <li>
            Wir wollen die Wehrpflicht, die seit dem Jahr 2011 nur ausgesetzt ist, ganz
            abschaffen.
          </li>
        </ul>
        <p>
          Wir erinnern daran, dass vor 84 Jahren – am 1. September 1939 – der Zweite
          Weltkrieg mit dem Überfall Nazi-Deutschlands auf Polen begann.
        </p>
        <p>
          Wir rufen dazu auf, gemeinsam zu demonstrieren: Nie wieder Faschismus, nie
          wieder Krieg!
        </p>
        <p className='text-end'>
          <strong>Antikriegskoordination Berlin, Juli 2023</strong>
        </p>
        <p className='muted small'>
          <i>Unterzeichner:innen:</i><br /> Rheinmetall Entwaffnen Berlin, DIE LINKE,
          AG Krieg und Frieden der IL Berlin, Naturfreunde Berlin,
          Internationale der Kriegsdienstgegner:innen, Internationalistische Jugendkommune Berlin,
          Revolutionäre Perspektive Berlin, North East Antifa [NEA], solid Berlin, Internationale Jugend Berlin,
          Internationalistischer Abend Berlin, Informationsstelle Militarisierung e.V., Solinetzwerk Berlin,
          Hände weg vom Wedding, SDS Berlin
        </p>

        <hr />

        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <div className='card mb-5 mt-3'>
              <div className='card-body text-center'>
                <p className='card-text'>
                  Auf der Demonstration sind alle Menschen willkommen, die die im Aufruf formulierten Grundsätze
                  teilen. Wir zeigen Solidarität mit den von Krieg betroffenen Menschen, nicht mit Staaten
                  oder ihren Regierungen. Wir bitten daher keine Nationalfahnen mitzubringen.
                </p>
                <p className='card-text'>
                  Menschen und Organisationen, die sich faschistisch, rassistisch oder sonstwie menschenfeindlich
                  äußern haben auf unserer Demonstration keinen Platz. Das gilt auch für Gruppen, die offen
                  für die Zusammenarbeit mit extremen Rechten sind.
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <p>
          Hier der Aufruf als leicht gekürzter
          Mobilisierungsflyer als <a href='/Antikriegstag-2023.pdf'>PDF</a>
        </p>
        <p>
          Sharepics:
        </p>
        <ul className='list'>
          <li>
            <a href='/Share-Antikriegstag-2023.jpg' alt='sharepic' title='sharepic'>
              Sharepic (landscape)
            </a>
          </li>
          <li>
            <a href='/Share-Antikriegstag-2023-square.jpg' alt='sharepic square' title='sharepic square'>
              Sharepic (square)
            </a>
          </li>
        </ul>

        <hr />

        <h2 id='presseschau'>Presseschau</h2>

        <ul>
          <li>
            <a href='https://www.jungewelt.de/artikel/458104.krieg-und-frieden-machtkampf-zwischen-nato-und-russland.html'>Junge Welt vom 31.8.2023</a>
          </li>

          <li>
            <a href='https://www.nd-aktuell.de/artikel/1175951.ukraine-krieg-berliner-friedensbuendnis-gegen-putin-und-die-nato.html'>Neues Deutschland vom 31.8.2023</a>
          </li>
          <li>
            <a href='https://www.nd-aktuell.de/artikel/1175995.friedensbewegung-antimilitarismus-und-antifaschismus-gehoeren-zusammen.html'>Neues Deutschland vom 3.9.2023</a>
          </li>
        </ul>

        <hr />

        <h2 id='pressemitteilung'>Auswertung, Pressemitteilung</h2>
        <p className='lead'>
          Veranstalter zufrieden: 700 Personen beteiligen sich an Demonstration gegen Krieg
          und Aufrüstung in Berlin am 2.9.
        </p>

        <p>
          Rund 700 Personen haben sich am 2. September 2023 an der Demonstration
          „Nein zu Krieg und Aufrüstung“ der Berliner Antikriegskoordination beteiligt.
        </p>
        <p>
          Nach einer Auftaktkundgebung auf dem Pariser Platz zog die Demonstration für eine
          Zwischenkundgebung vor das Karrierecenter der Bundeswehr am Bahnhof Friedrichstraße
          und fand ihren Abschluss auf dem Platz des 18. März.
        </p>
        <p>
          Zur Teilnahme aufgerufen hatten unter anderem, die Initiative Rheinmetall
          Entwaffnen, die Informationsstelle Militarisierung, die Internationale der
          Kriegsdienstgegner/innen, die Naturfreunde Berlin, die DIE LINKE und die
          Antifa Nord-Ost.
        </p>
        <p>
          Daniel Lücking, selbst ehemaliger Offizier der Bundeswehr, sprach davon, dass seit
          Beginn des Krieges gegen die Ukraine erzählt werde, dass Waffen Menschen retteten
          und nicht töteten. Das sei „absoluter Irrsinn“.
        </p>
        <p>
          Özlem Demirel, MdEP für DIE LINKE, verurteilte den russischen Angriff auf die
          Ukraine und ergänzte: „Wer aber glaubt, der EU und den USA ginge es bei ihren
          Waffenlieferungen um das Selbstbestimmungsrecht der Ukraine der irrt. Es geht den
          USA und der EU um eigene geopolitische Interessen in der Konkurrenz mit dem
          imperialen Rivalen Russland.“
        </p>
        <p>
          Die Mitorganisatorin einer friedenspolitischen Gewerkschaftskonferenz, Ulrike
          Eifler, forderte, dass Gewerkschafter*innen sich aktiv in die Friedensbewegung
          einbringen müssten. „Entspannungspolitik ist die Voraussetzung für erfolgreiche
          Umverteilungskämpfe, wie sie Gewerkschaften tagtäglich führen“, sagte sie.
        </p>
        <p>
          Die Initiative Rheinmetall Entwaffnen rief die Teilnehmenden dazu auf,
          „sich nicht vor den Karren der Kriegstreiber*innen“ spannen zu lassen und
          international für eine wirklich „solidarische, eine klassenlose Gesellschaft“
          zu kämpfen.
        </p>
        <p>
          Der polnische Sozialist und Kriegsgegner Andrzej Zebrowski warb für die
          Solidarität mit Kriegsgegner*innen in allen Ländern.
        </p>
        <p>
          Weitere Redner*innen waren unter anderem Uwe Hiksch, von den Naturfreunden,
          Pablo Flock von der Informationsstelle Militarisierung, Wolfram Bayer von der
          IDK, sowie Markus Tervooren, Geschäftsführer der Berliner VVN/BdA.
        </p>
        <p>
          Die Organisator*innen sind zufrieden mit Verlauf und Beteiligung an der
          Demonstration. Die Beteiligung habe die Erwartungen übertroffen.   Die
          Berliner Antikriegskoordination plant, weitere Initiativen gegen Krieg und
          Aufrüstung auf den Weg zu bringen.
        </p>

        <hr className='mb-5'/>

        <h2 className='mb-5' id='eindruecke'>Eindrücke und Bilder von der Demonstration</h2>

        <SimpleSlider images={images} className='mb-5'/>
      </main>
      <Footer />
    </div>
  )
}

export default AntikriegsTag2023
