import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Impressum from './components/Impressum';
import ProtestTag from './components/ProtestTag';
import Antikriegstag2023 from './components/Antikriegstag2023';
import ZweiterJahrestag from './components/ZweiterJahrestag';
import Rede2JahreUkraineKrieg from './components/Rede2JahreUkraineKrieg';
import Selbstverstaendnis from './components/Selbstverstaendnis';
import ErrorPage from './components/ErrorPage';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
   path: "impressum",
   element: <Impressum />,
   errorElement: <ErrorPage />,
   },
  {
   path: "tag-der-bundeswehr-2023",
   element: <ProtestTag />,
   errorElement: <ErrorPage />,
   },
   {
   path: "antikriegstag-2023",
   element: <Antikriegstag2023 />,
   errorElement: <ErrorPage />,
   },
   {
   path: "zweiter-jahrestag-2024",
   element: <ZweiterJahrestag />,
   errorElement: <ErrorPage />,
   },
   {
   path: "rede-zum-zweiten-jahrestag-2024",
   element: <Rede2JahreUkraineKrieg />,
   errorElement: <ErrorPage />,
   },
  {
   path: "selbstverstaendnis",
   element: <Selbstverstaendnis />,
   errorElement: <ErrorPage />,
   },
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
