import React from 'react'
import Rede2JahreUkraineKrieg from './Rede2JahreUkraineKrieg'



const ZweiterJahrestag = () => {
  return (
    <main className='cover-container px-3 textg-start p5'>
      <h1 className='mb-5'>
        <span style={{textDecoration: "underline", fontSize: "0.6em"}}>AUFRUF</span><br />
        Stoppt den Krieg in der Ukraine.<br />
        <small>Keine Waffenlieferungen. Asyl für Deserteure</small>
      </h1>


        <img src='/akk-zweiter-jahrestag.jpeg' alt='zweiter Jahrestag 2024' className='img img-fluid mb-5'/>

      <p>
        <a href='/rede-zum-zweiten-jahrestag-2024'>
          Zur Rede der <b>Antikriegskoordination</b>
        </a> gehalten auf der Kundgebung des
        Bündnisses <b>Stoppt das Töten</b> am 23.2.2024.
      </p>
      <h2 id='aufruf'>Aufruf</h2>
      <p>
        Seit zwei Jahren tobt in der Ukraine ein brutaler Krieg. Jeden Tag
        sterben hunderte Soldaten in den Schützengräben, jeden Tag werden Männer,
        Frauen und Kinder verwundet, getötet und vertrieben. Dieser Krieg hat
        Millionen ihre Heimat geraubt und zur Flucht gezwungen, er zerstört die
        Umwelt und das Klima. Der Krieg muss umgehend beendet werden!
      </p>
      <p>
        Wir haben vom ersten Tag an den russischen Angriff auf die Ukraine und
        Russlands fortgesetzte Eskalation des Kriegs verurteilt. Wir fordern den
        Abzug der russischen Truppen aus der Ukraine. Ebenso verurteilen wir
        die anhaltende Eskalation seitens der NATO-Staaten, die wie die EU einen
        Anteil an der Zuspitzung des Konfliktes um die Ukraine hat.
      </p>
      <p>
        Sowohl Russland als auch dem Westen geht es darum, ihren weltpolitischen
        Einfluss zu erhalten und auszudehnen. Russland hat kein Recht, ein anderes
        Land zu überfallen. Die NATO- und EU-Staaten, einschließlich der
        Bundesregierung, wollen ihre wirtschaftlichen und machtpolitischen
        Interessen durchsetzen. Sie planen bereits, wie die hochverschuldete
        Ukraine für das westliche Kapital am besten verwertet werden kann.
        Ausgerechnet die größte US-amerikanische Investmentfirma Blackrock soll den
        Wiederaufbau der Ukraine koordinieren.
      </p>
      <p>
        Unter der Schirmherrschaft von Internationalem Währungsfonds und Weltbank
        treibt die Selenskyj Regierung eine neoliberale Politik voran: weitere
        Privatisierung von öffentlichen Betrieben, Infrastrukturen und fruchtbaren
        Ländereien, weitere Deregulierung und Abbau des Arbeitsrechts, Abbau
        öffentlicher Dienstleistungen und weitere Öffnungen für das multinationale
        Kapital. Die Leidtragenden sind die ukrainische Bevölkerung und die
        Beschäftigten.
      </p>
      <p>
        Auch Menschen in anderen Ländern leiden unter den Folgen von rücksichtsloser
        Geopolitik, von Gewalt und Krieg. Die Kriege im Nahen und Mittleren Osten,
        im Jemen, Sudan, Mali oder in den kurdischen Gebieten gehen weiter und
        werden von EU, NATO und Bundesregierung befeuert. So wenig es in den
        Kriegen in Afghanistan, Syrien und Irak um »westliche Werte, Demokratie und
        Freiheit« ging und geht, so wenig werden sie jetzt in der Ukraine und an
        den anderen Kriegsschauplätzen verteidigt.
      </p>
      <p>
        Unsere Solidarität gilt den Menschen in der Ukraine, in Russland und
        weltweit, die unter dem Krieg und seinen Folgen leiden. Allen, die
        desertieren oder sich durch Kriegsdienstverweigerung und Flucht dem Krieg
        entziehen, allen, die Widerstand leisten, gebührt unsere Unterstützung.
      </p>
      <p>
        eDer Krieg in der Ukraine und um die Ukraine hat zu einer neuen Welle der
        Aufrüstung geführt und die Militarisierung beschleunigt. Die Bundesregierung
        will den Militärhaushalt um sieben Milliarden aufstocken, um das
        Zwei-Prozent-Ziel der NATO zu erreichen. Der Militärhaushalt soll ab 2027
        gut 60 Prozent größer sein als heute, fordert das Institut der deutschen
        Wirtschaft. Dieses Geld wird für Soziales, Klimaschutz und Bildung fehlen.
      </p>
      <p>
        Gleichzeitig hat die Bundesregierung 2023 Rüstungsexporte für 12 Milliarden
        Euro genehmigt und damit einen neuen Rekord aufgestellt. Konzerne wie
        Rheinmetall und Krauss-Maffei Wegman fahren Rekordprofite ein. Wir sprechen
        uns gegen alle Rüstungsexporte aus - egal ob in die Ukraine, Israel, Saudi –
        Arabien oder anderswo.
      </p>
      <p>
        Verteidigungsminister Pistorius möchte die Bundeswehr „kriegstüchtig“
        machen, Inzwischen ist eine Brigade der Bundeswehr in Litauen fest stationiert.
        Der Druck, mehr junge Menschen für die Bundeswehr zu rekrutieren, nimmt zu.
        Wir lehnen Auslandseinsätze der Bundeswehr und Militarisierung nach innen ab.
     </p>
      <p>
        Wir wollen raus aus dem Konkurrenzkampf von Nationen, Machtblöcken und
        Konzernen – hin zu einer Welt ohne Krieg, in der Menschen vor Profiten
        stehen und ein gutes Leben für alle möglich wird.
      </p>
      <p>
        Am zweiten Jahrestag des Krieges setzen wir uns dafür ein, dass
        <ul>
          <li>
            die Bundesregierung die Waffenlieferungen an die Ukraine stoppt und sich
            für einen sofortigen Waffenstillstand einsetzt;
          </li>
          <li>
            allen Geflüchteten Aufnahme und Bleiberecht, allen Deserteur*innen Asyl
            gewährt wird;
          </li>
          <li>
            die Gewerkschaften und sozialen Initiativen in der Ukraine unterstützt
            werden, die für ihre sozialen Rechte kämpfen und sich gegen den
            neoliberalen Ausverkauf ihres Landes stellen;
          </li>
          <li>
            die Aufrüstung und Militarisierung Deutschlands endet. Wir fordern von
            der Bundesregierung, die für die Rüstung vorgesehenen Milliarden Euro
            für Soziales, Gesundheit, Bildung und Klimaschutz auszugeben;
          </li>
          <li>
            die Werbung der Bundeswehr an Jobcentern, Schulen und Hochschulen sowie
            die Rekrutierung Minderjähriger unterbleibt und die Wehrpflicht, die seit
            dem Jahr 2011 nur ausgesetzt ist, nicht wieder eingeführt wird.
            Auch die Forderung nach der Einführung einer Dienstpflicht lehnen wir ab.
          </li>
         </ul>
        </p>
      <p>
        Mit diesen Inhalten beteiligen wir uns an den geplanten Aktionen anlässlich
        des 2. Jahrestags des Angriffs auf die Ukraine und rufen auch andere Gruppen
        und Initiativen auf, gegen Krieg und Aufrüstung auf die Straße zu gehen.
        Dabei haben Menschen und Organisationen, die sich faschistisch, rassistisch
        oder sonst wie menschenfeindlich äußern, keinen Platz. Das gilt auch für
        Gruppen, die offen für die Zusammenarbeit mit extremen Rechten sind.
      </p>

      <p className='text-end'>
        <strong>Antikriegskoordination Berlin, Februar 2024</strong>
      </p>
    </main>
  )
}

export default ZweiterJahrestag
