import React from 'react'
import { Fade } from 'react-slideshow-image'



const SimpleSlider = ({images}) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const divStyle = {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'right',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '550px'
  }

  const spanStyle = {
    padding: '5px',
    color: '#ffffff'
  }


  return(
    <div className='simple-slide'>
      <Fade {...settings} >
        {images.map((slideImage, idx) => (
          <div key={idx}>
            <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.name})` }}>
              <span style={spanStyle}>{slideImage.caption}</span>
            </div>
          </div>
        ))}
      </Fade>
    </div>
  )
}

export default SimpleSlider
