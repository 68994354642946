const Footer = () => {
  return(
    <footer className="text-white-50 pb-5 row mt-5">
      <div className='col'>
        <ul className='list-inline'>
          <li className='list-item'>
            <a href='/impressum'>Impressum</a>
          </li>
        </ul>
      </div>
      <div className='col'>
        Vergangene Aktionen
        <ul className='list-inline'>
          <li className='list-item'>
            <a href='/Antikriegstag-2023'>
              Antikriegstag 2023
            </a>
          </li>
          <li className='list-item'>
            <a href='/tag-der-bundeswehr-2023'>
              Protest gegen Tag der Bundeswehr 2023
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
