import Navigation from './Navigation'
import Footer from './Footer'

const Impressum = () => {
  return(
    <div className="container">
      <Navigation />
      <main className="cover-container px-3 text-start p-5">
        <h1>Impressum</h1>
        <p>
          Hermann Nehls, <br />
          Wipperstraße 6,  <br />
          12055 Berlin
        </p>
        <h2>Kontakt</h2>
        <p>E-Mail: antikriegskoordination@riseup.net</p>
      </main>
      <Footer />
    </div>
  )
}

export default Impressum
