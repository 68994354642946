import Navigation from './Navigation'

const Selbstverstaendnis = () => {
  return(

     <div className='container'>
      <Navigation />
      <main className='cover-container px-3 textg-start p5'>
        <h1>
          <span style={{textDecoration: "underline", fontSize: "0.6em"}}>
            Stand 12.7.2023
          </span><br />
        Selbstverständnis zum Krieg in der Ukraine
        </h1>

        <p>
         Vor dem Hintergrund des immer brutaler werdenden Krieges in der Ukraine ist es gerade jetzt wichtig,
          sich gegen Militarisierung, gegen Aufrüstung, gegen die weitere Eskalation und für Verhandlungen
          auszusprechen. Es gibt keine militärische Lösung dieses Krieges. Die Bundesregierung trägt zur
          Eskalation bei, indem sie weiter massiv Waffen an die Ukraine liefert und keine Bemühungen
          unternimmt, Friedensverhandlungen zu fördern.
        </p>

        <p>
          Was klar ist: Der russische Angriffskrieg auf die Ukraine ist völkerrechtswidrig.
          Er birgt die Gefahr eines globalen Flächenbrandes und eines atomaren Krieges. Dieser
          Krieg ist nicht nur ein Krieg Russlands gegen die Ukraine, er ist auch ein Krieg um die Ukraine,
          nämlich ein Machtkampf zwischen der NATO (USA und EU) auf der einen und Russland auf der anderen Seite.<br />
          Zur Vorgeschichte des Krieges gehört, dass die NATO seit Jahren eine Verschärfung des Konfliktes in Kauf
          genommen hat. Zur Vorgeschichte gehört auch, dass Russland sich zu einer imperialistischen Macht entwickelt
          hat. Deshalb stehen wir weder auf der Seite Russlands noch auf der Seite der NATO. Der Krieg muss beendet
          werden. Es gibt keine Alternative zum Frieden. Die russischen Truppen müssen unverzüglich abziehen.
        </p>

        <p>
          Jeden Tag sterben hunderte Menschen in diesem Krieg. Unsere Solidarität gilt den Menschen in der
          Ukraine und in Russland, die leiden, Widerstand leisten oder flüchten müssen. Wir sind mit denen
          solidarisch, die desertieren und sich durch Kriegsdienstverweigerungund Flucht dem Krieg entziehen
          und ihr Leben schützen wollen, die weltweit unter Krieg und Verfolgung leiden – ihnen muss Asyl und
          Bleiberecht gewährt werden.<br />
          Wir müssen raus aus dem Konkurrenzkampf von Nationen, Machtblöcken und Konzernen hin zu einer Welt
          ohne Krieg, in der Menschen vor Profiten stehen und ein gutes Leben für alle möglich wird. Dafür
          gilt es, sich jetzt sowohl gegen den russischen Einmarsch in der Ukraine zu positionieren und
          zugleich hier vor Ort gegen eine Unterstützung der NATO und gegen die Militarisierung in Deutschland
          zu kämpfen. Wir wollen die Solidarität von unten aufbauen!
        </p>

        <ul>
          <li>
            Sofortiger Waffenstillstand und Stopp der Kriegshandlungen
          </li>
          <li>
            Keine Eskalation durch Russland und die NATO. Stoppt die Waffenlieferungen!
          </li>
          <li>
            Solidarität mit Antikriegsbewegungen und Kriegsdienstverweiger*innen in Russland und der Ukraine
          </li>
          <li>
            Nein zur Aufrüstung der Bundeswehr und der NATO – Geld für Klima und Soziales
          </li>
          <li>
            Aufnahme und Bleiberecht für alle Geflüchteten, Asyl für alle Deserteur*innen
          </li>
        </ul>
      </main>
    </div>
  )
}

export default Selbstverstaendnis
