const Navigation = () => {
  return (
    <header className="mb-5 p-3">
      <div>
        <h3 className="float-md-start mb-0">
          Antikriegskoordination
        </h3>
        <nav className="nav nav-masthead justify-content-center float-md-end">
          <a className="nav-link fw-bold py-1 px-0 active" aria-current="page" href="/">
            Home
          </a>
          <a className="nav-link fw-bold py-1 px-0 active" aria-current="page" href="/selbstverstaendnis">
            Selbstverständnis
          </a>
        </nav>
      </div>
    </header>
  )
}

export default Navigation

