import Navigation from './Navigation'
import Footer from './Footer'

const Rede2JahreUkraineKrieg = () => {
  return(
    <div className="container">
      <Navigation />
      <main className="cover-container px-3 text-start p-5">
        <h1>
          <small>2 Jahre Krieg in der Ukraine:</small><br />
          Stoppt den Krieg - keine Waffenlieferungen - Asyl für Deserteure aus Russland und der Ukraine
        </h1>

        <p>
        <small>
        Rede für die Berliner Antikriegskoordination
        auf der Kundgebung des Bündnisses „Stoppt das Töten“ am 23.2.2024
        </small>
        </p>
        <p>
        Der russische Überfall auf die Ukraine hat unermessliches Leid über
        die Bevölkerung in  der Ukraine und auch über die Bevölkerung in
        Russland gebracht hat.
        </p>
        <p>
        Jeden Tag sterben hunderte Soldaten in den Schützengräben.<br />
        Sie werden von der russischen und der ukrainischen Regierung in nicht
        gewinnbare Schlachten
        geschickt.<br />
        Jeden Tag sterben Zivilistinnen und Zivilisten. <br />
        Immer tödlichere Waffen werden in dem Krieg eingesetzt.
        Sowohl die russische als auch die
        ukrainischen Armee setzen international geächtete Streubomben ein.<br />
        Dass Russland in der Ukraine Streubomben einsetzt, ist schon
        länger bekannt. Aber auch die USA  vor
        deren Botschaft wir hier demonstrieren machen keinen Hehl daraus,
        dass sie Streubomben an die Ukraine
        geliefert haben.<br />
        <b>Die russischen und ukrainischen Soldaten haben in diesem Krieg nichts zu gewinnen. Er muss muss gestoppt
        werden. Sofort.</b>
        </p>
        <p>
        Wir haben uns als Antikriegskoordination zusammengefunden, weil wir laut und deutlich unsere Stimme
        erheben wollen gegen die Kriegstreiberei aus Moskau, Washington, aus Berlin und Brüssel.
        </p>
        <p>
        Wir setzen der herrschenden Erzählung, die Ukraine müsste nur noch weiter mit Waffen versorgt werden,
        dann würde bald Frieden herrschen, unseren Protest entgegen.
        </p>
        <p>
        Haben denn die Waffenlieferungen der letzten Monate den Krieg beendet?
        Nein, sie haben das genauso wenig getan wie die Sanktionen, die verhängt wurden.
        </p>
        <p>
        <b>
        Die Waffenlieferungen an die Ukraine haben eine neue Spirale der Eskalation eingeleitet. Sie müssen sofort
        gestoppt werden.
        </b>
        </p>
        <p>
        Wir benennen die Verantwortung der russischen Regierung für den Angriff.
        </p>
        <p>
        Zugleich reden wir von den Hintergründen und der Vorgeschichte des Krieges und dessen Charakter.
        </p>
        <p>
        Denn dieser Krieg ist nicht nur ein Krieg Russlands gegen die Ukraine, es ist auch ein Krieg von Russland und
        der NATO um die Ukraine. Zwei große imperialistische Blöcke führen einen Krieg, um zu entscheiden, zu
        welchem Machtbereich das große und potentiell so reiche Land Ukraine in Zukunft gehören soll, zum
        Machtbereich Russlands oder dem der NATO. 
        Und in diesem Sinne sind die NATO und die EU sind Teil des Problems und nicht der Lösung.
        </p>
        <p>
        Das betrifft sowohl die Vergangenheit, die Osterweiterung der NATO und der EU, als auch die aktuelle
        Entwicklung des Krieges. 
        Schon seit Jahren werden europaweit gemeinsame Rüstungsprojekte geplant und umgesetzt. So soll die EU zum
        Global Player werden, der militärisch unabhängiger von den USA ist.
        Deutschland ist dabei ein zentraler Akteur, die EU-Militarisierung voranzutreiben.
        </p>
        <p>
        Und die Logik ist klar: Wer als Erstes in der Lage ist, neue Waffensysteme mit Künstlicher Intelligenz
        und teilautonomen Systemen in großem Umfang zu produzieren und einzusetzen, hat rüstungspolitisch und
        damit auch machtpolitisch die Nase vorn.
        </p>
        <p>
        Die Bundesregierung will, dass Deutschland dabei eine Führungsrolle übernimmt.
        </p>
        <p>
        Und so ist es kein Zufall, dass am 27. Februar 2022 Olaf Scholz nicht nur Hundertmilliarden Sondervermögen
        für die Bundeswehr, sondern auch gleichzeitig eine Entscheidung für die Beschaffung bewaffneter
        Kampfdrohnen angekündigt hat. Auch dagegen demonstrieren wir heute.
        </p>
        <p>
        <b>
        Der Krieg in der Ukraine und um die Ukraine hat auch zu einer neuen Welle der Aufrüstung geführt und die
        Militarisierung beschleunigt. Dagegen demonstrieren wir heute genauso wie für die sofortige Beendigung des
        Krieges in der Ukraine.
        </b>
        </p>
        <p>
        Die Bundesregierung will den Militärhaushalt um immer mehr Milliarden aufstocken. Dieses Geld fehlt jetzt
        schon und wird weiter fehlen für Soziales, Klimaschutz und Bildung.
        </p>
        <p>
        Und ich will auch darüber spreche, wer Nutznießer ist von dieser Entwicklung.
        Konzerne wie Rheinmetall und Krauss-Maffei Wegman. Sie fahren Rekordprofite ein.
        Hier am Pariser Platz da ist die Berliner Repräsentanz von Rheinmetall und deswegen ist es ein richtiger
        Ort, an dem wir heute demonstrieren, um deutlich zu machen: Wir wollen den Kriegsprofiteuren das Handwerk legen!
        Wir sprechen uns gegen alle Rüstungsexporte aus - egal ob in die Ukraine, Israel, Saudi – Arabien oder anderswo
        auf der Welt.
        </p>
        <p>
        Wir sagen: „Wer den Frieden will, muss den Widerstand gegen den Krieg vorbereiten“
        Das gilt für uns als Friedensbewegung und wir wissen alle, wie enorm schwierig das momentan ist und wie notwendig es
        eigentlich wäre, eine große und geeinte Friedensbewegung zu haben.
        </p>
        <p>
        Das gilt für uns, aber das gilt auch für die Ukraine und für die russische Föderation. Das ist verdammt schwer
        angesichts von Repression und immer brutaleren Mobilisierungswellen.
        Ich finde es mutig, dass sich immer mehr junge Männer auch in der Ukraine der Rekrutierung entziehen.
        Diesen Widerstand unterstützen wir voll und ganz.<br />
        <b>
        Wir fordern Asyl für Deserteure aus Russland, der Ukraine und Belarus. Wir unterstützen alle, die sich dem
        grassierenden Nationalismus in ihrem jeweiligen Land entgegenstellen.  
        </b>
        Denn wollen raus aus dem Konkurrenzkampf von Nationen, Machtblöcken und Konzernen – hin zu einer Welt ohne Krieg,
        in der Menschen vor den Profiten stehen und ein gutes Leben für alle möglich wird.
        </p>
        <p>
        <b>
        Russland hat kein Recht, ein anderes Land zu überfallen.
        Die NATO- und EU-Staaten, einschließlich der Bundesregierung, haben nicht das Recht, ihre wirtschaftlichen und
        machtpolitischen Interessen mit militärischer Gewalt durchzusetzen.
        </b>
        </p>
        <p>
        <b>
        Dieser Krieg muss enden. Sofort.<br />
        Keine Waffenlieferungen mehr.<br />
        Für einen sofortiger Waffenstillstand.<br />
        Für Asyl für Kriegsdienstverweigerer aus Russland und der Ukraine!
        </b>
        </p>
      </main>
      <Footer />
    </div>
  )
}

export default Rede2JahreUkraineKrieg
