import logo from './logo.svg';
import './App.css';
import ZweiterJahrestag from './components/ZweiterJahrestag'
import Navigation from './components/Navigation'
import Footer from './components/Footer'

const App = () => {
  return (
    <div className="container">
      <Navigation />
      <main className="cover-container px-3 text-start p-5">
        <ZweiterJahrestag />
      </main>
      <Footer />
    </div>
  );
}

export default App;
